import { Link } from "gatsby";
import React, { useContext } from "react";
import Layout from "../components/layout/layout";
import { LanguageContext } from "../context/language-context";
import t from "../resources/translations";
import { StaticImage } from "gatsby-plugin-image";

const NotFound = () => {
  const l = useContext(LanguageContext).locale;
  return (
    <Layout>
      <div className="my-20 p-6 max-w-prose mx-auto text-center">
        <h1 className="is-h1">{t.nonTrovata[l]}</h1>
        <div className="max-w-[200px] mx-auto">
          <StaticImage
            layout="fullWidth"
            placeholder="blurred"
            formats={["webp", "auto"]}
            alt=""
            src={"../resources/images/404.png"}
          />
        </div>
        <div className="mt-10">
          <Link to={t.ghLink[l]} className="is-button">
            {t.torna[l] + " Home"}
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default NotFound;
